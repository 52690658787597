<template>
  <div :class="['album-card-info',display === 'row' ? 'row-card' : '']" @click="toAlbumDetail">
    <img :src="albumInfo.backgroundImage" class="album-image" />

    <div :class="[display === 'row' ? 'row-right' : '']" style="flex: 1">
      <div class="album-title">{{ albumInfo.title }}</div>
      <div class="album-content" style="display: flex">
        <div style="display: flex;align-items: center">

          <span>{{ albumInfo.content }}</span>
          <span class="vip-text"
                v-if="albumInfo.price > 0 && albumInfo.type !== 'MoneyTalks'">{{ getVipChannel(albumInfo.type) }}VIP</span>
          <span v-else-if="albumInfo.price === 0 && albumInfo.type !== 'MoneyTalks'" class="vip-text">免费</span>
        </div>
        <span class="companyName">{{ albumInfo.tag[0] }}</span>

      </div>



      <div class="album-content">{{ formatDatetime(new Date(albumInfo.updateTime)) }}</div>

    </div>

  </div>
</template>

<script>
import { formatDatetime } from "@/utils/dateutils";
import AlbumType from "@/utils/AlbumType";

export default {
  name: "AlbumCard",
  mixins:[AlbumType],
  props: {
    albumInfo: {
      type: Object
    },
    display: {
      type: String,
      default: "column"
    }
  },
  methods: {
    formatDatetime,
    toAlbumDetail() {
      this.$router.push({
        path: "/album/detail",
        query: {
          albumId: this.albumInfo.id
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.row-card {
  flex-direction: row !important;
  width: 23% !important;
  margin-bottom: 20px !important;
  //border: none !important;
  min-width: 20% !important;

  .album-image {
    width: 80px !important;
    height: 80px !important;
  }

  .row-right {
    margin-left: 20px;

    .album-title {
      font-size: 20px;
    }

    .album-content {
      font-size: 16px;
    }
  }

}

.row-card:hover{
  transform: scale(1.05) !important;

}


.vip-text{
  background: #efbd84;
  color: #5d352a;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 14px;
}

.album-card-info {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid rgba(96, 96, 96, 0.27);
  margin-right: 30px;
  margin-bottom: 38px;
  width: 324px;
  cursor: pointer;
  transition: transform 0.3s ease;

  .album-image {
    width: 100%;
    border-radius: 5px;
    object-fit: cover;

  }

  .album-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
    color: #000000;
  }

  .album-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgb(161, 167, 183);
    margin-top: 10px;
    white-space: pre-line;
    justify-content: space-between;
  }

  .companyName{
    padding: 4px 10px;
    display: flex;
    font-size: 16px;
    background: rgba(0, 82, 254, 0.1) !important;
    border-radius: 5px;
    color: #0052FE !important;
  }
}

.album-card-info:hover {
  transform: scale(1.1);
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5)
}
</style>