<template>
  <div class="left-chat-box">
    <div style="display: flex">
<!--      <image :src="avatar" class="avatar" />-->
      <div :class="['chat-content-box','m-l-15',isContentCard?'contentBox':'']">
        <div :style="{'width':autoFitWidth?'':'100%;'}" class="chat-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftChatItem",
  props: {
    avatar: {
      type: String
    },
    autoFitWidth: {
      type: Boolean,
      default: true
    },
    isContentCard:{
      type:Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">

.contentBox{
  //max-width: calc(100% - 15px - 40px );
}

.left-chat-box {
  margin: 20px 30px 0 30px;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    flex-shrink: 0;
  }

  .chat-content-box {
    flex: 1;
    position: relative;
    display: flex;
  }

  .chat-content-box:before {
    content: "";
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 8px solid #fff;
    border-bottom: 10px solid transparent;
    //transform: translateY(-50%);
    top: 20px;
  }

  .chat-content {
    padding: 30px;
    border-radius: 20px;
    background-color: #fff;
  }
}
</style>