import { requestGet, requestPost } from "@/server/axios.js";
import { baseURL } from "@/server/config.js";

export const getHomeData = (homeConfigKey) => {
    return requestGet(`${baseURL}/v2/home/homeData`, {
        homeConfigKey:homeConfigKey
    })
}
export const getTagListV2 = (homeConfigId) => {
    return requestGet(`${baseURL}/v2/home/tagList`, {
        homeConfigId:homeConfigId

    })
}
export const postSelectedTagV2 = (selectDataList,homeConfigId,clickAndSave) => {
    return requestPost(`${baseURL}/v2/home/getResolveV2`, {
        selectDataList: selectDataList,
        homeConfigId:homeConfigId,
        save:clickAndSave
    })
}