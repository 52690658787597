<template>
  <div class="home" id="home">
    <div class="bg" />
    <div :style="{'padding-top':'0px'}" id="main-view" class="main-view">

      <div v-if="homeData">
        <left-chat-item :avatar="homeData.avatar">
          <template v-slot>
            <span class="user-name">你好，我是<span style="color: #0052FE">OfferTalks</span>创始人花姐</span>
          </template>
        </left-chat-item>
        <left-chat-item :avatar="homeData.avatar">
          <template v-slot>
            <span class="user-desc" style="white-space: pre-line">{{ homeData.content }}</span>
          </template>
        </left-chat-item>
      </div>

      <div class="tag-step" v-if="tagGroupList">
        <div v-for="(tagInfo,tagGroupIndex) in tagGroupList" :key="tagInfo">
          <left-chat-item :avatar="homeData.avatar" v-if="tagGroupIndex <= currentStep">
            <template v-slot>
              <div class="card-left" :id="'tag-group-' + (tagGroupIndex)">
                <span class="start-text">{{ tagInfo.content }}</span>
                <div style="display: flex;margin-top: 10px;flex-wrap: wrap" v-if="tagInfo.tagType !== 'member_input'">
                  <span v-for="(tagItem,tagItemIndex) in tagInfo.tagList"
                        :key="tagItem"
                        :class="['tag-item',tagItem.selected?'tag-item-selected':'']"
                        @click="selectTag(tagGroupIndex,tagItemIndex)">
                    {{ tagItem.label }}
                  </span>
                </div>
                <div v-else-if="tagInfo.tagType === 'member_input'" style="display: flex;align-items: center">
                  <el-input placeholder="请输入" :ref="'memberInput' + tagGroupIndex"
                            style="width: 400px;margin-right: 20px;font-size: 18px"
                            type="textarea"
                            :rows="3"
                            v-model="memberInput[tagGroupIndex]"
                            :disabled="tagGroupIndex < currentStep"/>
                  <el-button plain style="background-color: #0052FE;color: #fff;border: none;height: auto"
                              :disabled="tagGroupIndex < currentStep"
                              @click="inputText(tagGroupIndex)">确定</el-button>
                </div>
              </div>
            </template>
          </left-chat-item>


          <div v-if="selectedTagList.length > tagGroupIndex">
            <div style="display: flex;flex-direction: row-reverse;margin-top: 15px" v-if="selectedTagList[tagGroupIndex].label">
              <div class="tag-answer">{{ selectedTagList[tagGroupIndex].label }}</div>
            </div>
          </div>


        </div>
      </div>


      <div v-if="showReset"  class="recommendData">

        <left-chat-item :avatar="homeData.avatar" is-content-card>
          <template v-slot>
            <div class="card-left" style="margin-bottom: 20px">
              <div class="taro_html user-desc"
                   v-html="homeData.resolveContent"></div>

              <image class="resolve-image"
                     mode="aspectFill"
                     v-if="getFileType(homeData.resUrl) === 'image'"
                     :src="homeData.resUrl" />
              <video v-if="getFileType(homeData.resUrl) === 'video'"
                     :src="encodeURI(homeData.resUrl)"
                     :autoplay="false"
                     style="max-width: 100%"
                     :controls="true"
              />
              <div v-if="getFileType(homeData.resUrl) === 'audio'" class="audio"
                   @click="playAudio(homeData.resUrl)">
                <div style="background: #0052FE;border-radius: 15px;padding: 6px 12px;display: flex">
                  <span class="play-text">语音播放</span>
                </div>
              </div>
            </div>
          </template>
        </left-chat-item>


        <left-chat-item v-if="recommendData && recommendData.recommendAlbumList" :avatar="homeData.avatar" is-content-card>
          <template v-slot>
            <div v-for="albumCardV2 in recommendData.recommendAlbumList" :key="albumCardV2">
              <album-card :album-info="albumCardV2" size="small" display="row" />
            </div>
          </template>
        </left-chat-item>

      </div>

      <div class="resetSelectedTag" @click="resetSelectedTag"  v-if="showReset">重新选择</div>


    </div>
  </div>
</template>

<script>


import { getHomeData, getTagListV2, postSelectedTagV2 } from "@/apis/v2/home";
import LeftChatItem from "@/components/v2/LeftChatItem";
import AlbumCard from "@/components/v2/AlbumCard";
import { mapActions, mapGetters } from "vuex";
import cookie from "@/utils/cookie";
import { getLoginDetail } from "@/apis/account";

export default {
  components: { AlbumCard, LeftChatItem },
  data() {
    return {
      homeData: null,
      tagGroupList: [],
      currentStep: 0,
      selectedTagList: [],
      recommendData: null,
      playing: false,
      scrollHeight: null,
      homeConfigKey: null,
      showReset:false,
      memberInput:[]
    };
  },
  created() {
    this.homeConfigKey = this.$route.query.key;
    if (!cookie.getCookie("token")) {
      this.UPDATE_LOGIN_VISIABLE(true);
    }else{
      if(!this.userInfo){
        //有cookie 先获取
        getLoginDetail().then(res => {
          this.UPDATE_USER_INFO(res.data);
        })
      }else{
        this.initUserData()
      }
    }
  },
  watch: {
    userInfo() {
      this.initUserData()
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    ...mapActions(["UPDATE_WECHAT_SERVICE_VISIBLE", "UPDATE_VIP_VISIBLE", "UPDATE_USER_INFO", "UPDATE_LOGIN_VISIABLE"]),
    initUserData(){
      console.log('.........',this.userInfo)
      if (this.userInfo) {
        this.getHomeConfig();
      } else {
        this.UPDATE_LOGIN_VISIABLE(true);
      }
    },
    resetSelectedTag() {
      this.currentStep = 0;
      this.selectedTagList = [];
      this.recommendData = null;
      this.showReset = false
      this.getHomeConfig(true);
    },
    playAudio() {
    },
    getFileType(url) {
      if (!url) {
        return "unknown";
      }
      let fileName = url.toLowerCase();
      if (fileName.endsWith(".png") || fileName.endsWith(".jpg") || fileName.endsWith(".gif") || fileName.endsWith(".jpeg")) {
        return "image";
      } else if (fileName.endsWith(".mp4")) {
        return "video";
      } else if (fileName.endsWith(".mpeg") || fileName.endsWith(".ogg") || fileName.endsWith(".wav") || fileName.endsWith(".m4a")) {
        return "audio";
      } else {
        return "unknown";
      }
    },

    getHomeConfig(reset = false) {
      getHomeData(this.homeConfigKey).then(res => {
        let tempData = res.data;
        if (tempData.avatar && tempData.avatar[0]) {
          tempData.avatar = tempData.avatar[0];
        }
        this.homeData = tempData;
        this.initTagList(reset);
      });
    },
    initTagList(reset = false) {
      getTagListV2(this.homeData.id).then(res => {
        const tagList = res.data.tagList
        const dealTags = ['service_tags', 'job_tags']
        dealTags.forEach(i => {
          tagList.forEach(tagGroup=>{
            if(tagGroup.tagType === i){
              tagGroup.tagList = tagGroup.tagList.filter(tag => tag.relateTag === this.homeConfigKey)
            }
          })
        })

        console.log('deal tag after',tagList)

        this.tagGroupList = tagList
        console.log("tagGroupList", this.tagGroupList);
        if(res.data.selectDataList && !reset){
          //之前有选中过
          const selectDataList = res.data.selectDataList

          let match = this.tagGroupList.length === selectDataList.length

          if(this.tagGroupList.length === selectDataList.length){
            for (let i = 0; i < this.tagGroupList.length; i++) {
              if(this.tagGroupList[i].tagType !== selectDataList[i].tagType){
                match = false
              }
            }
          }

          console.log('match value ' , match)

          if (match) {
            //初始化之前选中的
            for (let i = 0; i < this.tagGroupList.length; i++) {
              if(this.tagGroupList[i].tagType === 'member_input'){
                this.memberInput[i] = selectDataList[i].value
                this.inputText(i,false)
              }else{
                this.selectTag(i, this.tagGroupList[i].tagList.map(x => {
                  return x.label
                }).indexOf(selectDataList[i].value) , false)

              }

            }
          }

        }
      });
    },
    inputText(tagGroupIndex,clickAndSave = true){
      console.log(this.memberInput)
      if (tagGroupIndex < this.currentStep) {
        //不能再点击之前的
        return
      }
      this.selectedTagList.push({
        ...this.tagGroupList[tagGroupIndex],
        value: this.memberInput[tagGroupIndex],
      })
      this.nextStep(clickAndSave)

    },
    selectTag(tagGroupIndex, tagItemIndex, clickAndSave = true) {
      console.log("scroll", clickAndSave);
      if (tagGroupIndex < this.currentStep) {
        //不能再点击之前的
        return;
      }
      this.tagGroupList[tagGroupIndex].tagList[tagItemIndex].selected = true
      this.selectedTagList.push({
        ...this.tagGroupList[tagGroupIndex],
        label: this.tagGroupList[tagGroupIndex].tagList[tagItemIndex].label,
        value: this.tagGroupList[tagGroupIndex].tagList[tagItemIndex].label,
      })
      this.nextStep(clickAndSave)

    },
    nextStep(clickAndSave=true){
      if (this.currentStep >= this.tagGroupList.length - 1) {
        this.saveSelectedTag(clickAndSave)
      }
      if(clickAndSave){
        this.currentStep = this.currentStep + 1;
        // 获取页面的总高度
        setTimeout(() => {
          const totalHeight = document.body.scrollHeight;
          console.log("scroll -----  ", totalHeight);
          // 滑动到页面底部
          window.scrollTo({
            top: totalHeight,
            behavior: "smooth" // 平滑滚动
          });
        }, 300);
      }else{
        this.currentStep = this.currentStep + 1
      }
    },
    saveSelectedTag(clickAndSave=true) {
      //提交标签，获取推荐数据
      console.log(this.selectedTagList);
      postSelectedTagV2(this.selectedTagList,this.homeData.id,clickAndSave).then(res => {
        console.log(res.data);
        this.recommendData = res.data;

        setTimeout(() => {
          // 滑动到页面底部
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth" // 平滑滚动
          });
        }, 300);


      }).finally(()=>{
        this.showReset = true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;

  .bg {
    width: 100%;
    height: 500px;
    background: linear-gradient(360deg, #FFFFFF 0%, #B3CCFF 100%);
    border-radius: 2px;
    opacity: 0.7;
  }

  .main-view {
    width: 60%;
    margin: -480px auto 40px auto;

    .user-name {
      font-size: 30px;
      color: #19191A;
      line-height: 2;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
    }

    .user-desc {
      font-size: 24px;
      color: #19191A;
      line-height: 2;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
    }

    .start-text {
      font-size: 30px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #0052FE;
      white-space: pre-line;
      line-height: 1.5;
    }

    .card-left {
      background: #FFFFFF;
      border-radius: 0px 40px 40px 40px;
    }

    .tag-step {
      .tag-item {
        cursor: pointer;
        margin-right: 16px;
        font-size: 24px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 1.5;
        padding: 10px 24px;
        background: #FFFFFF;
        border-radius: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        margin-top: 20px;
      }

      .tag-item-selected {
        border-color: #0052FE;
        color: #0052FE;
      }

      .tag-answer {
        margin-right: 30px;
        background: #0052FE;
        border-radius: 100px 0px 100px 100px;
        padding: 20px 30px;
        font-size: 30px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.5;
      }
    }

    .recommendData {

      .resolveContent-title {
        font-weight: bold;
        font-size: 34px;
        color: #0052FE;
      }

      .resolve-image {
        width: 100%;
      }

      .audio {
        flex-direction: row-reverse;
        display: flex;
      }

      .play-text {
        margin-left: 12px;
        font-size: 26px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 1.5;
      }
    }

    .resetSelectedTag {
      cursor: pointer;
      font-weight: 400;
      font-size: 36px;
      text-align: center;
      margin: 40px;
      padding-bottom: 40px;
      font-family: PingFangSC, PingFang SC;
      color: #0052FE;

    }
  }


}
</style>
