
export const formatDate = (date) => {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  return y + '-' + m + '-' + d;
}
export const getNowDay = (date) => {
  if(!date){
    date = new Date()
  }
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var day = date.getDay()
  var showDay = ''
  if(day === 0){
    showDay = "日"
  }else if (day === 1){
    showDay = "一"
  }else if (day === 2){
    showDay = "二"
  }else if (day === 3){
    showDay = "三"
  }else if (day === 4){
    showDay = "四"
  }else if (day === 5){
    showDay = "五"
  }else if (day === 6){
    showDay = "六"
  }

  return m + '月' + d + "日" + " 星期" + showDay;
}
export const formatDatetime = (date) => {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  var h = date.getHours()
  h = h < 10 ? ('0' + h) : h;
  var min = date.getMinutes()
  min = min < 10 ? ('0' + min) : min;

  return y + '.' + m + '.' + d + ' ' + h + ':' + min;
}

