export default {
    methods: {
        getVipChannel(albumType){
            if (albumType === 'MoneyTalks') {
                return '岗位'
            } else if (albumType === 'OfferTalks') {
                return '项目'
            } else if (albumType === 'MasterTalks') {
                return '简历'
            } else if (albumType === 'UserTalks') {
                return '面经'
            }
        },
        getAlbumTypeTxt(albumType) {
            if (albumType === 'MoneyTalks') {
                return '机会超多'
            } else if (albumType === 'OfferTalks') {
                return 'Offer多多'
            } else if (albumType === 'MasterTalks') {
                return '简历神器'
            } else if (albumType === 'UserTalks') {
                return '面试必过'
            }
        }
    }
}

